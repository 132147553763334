import type * as Types from '../../../../typings/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
type UserMagicLinkCreateMutationVariables = Types.Exact<{
  input: Types.UserMagicLinkCreateInput;
}>;


type UserMagicLinkCreateMutationPayload = { __typename?: 'Mutation', userMagicLinkCreate: { __typename?: 'UserMagicLinkCreatePayload', error: { __typename?: 'ServerError', message: string, serverErrorCode: Types.ServerErrorCode } | { __typename?: 'UserMagicLinkError', message: string, userMagicLinkCode: Types.UserMagicLinkErrorCode } | null } };


 const UserMagicLinkCreateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UserMagicLinkCreate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UserMagicLinkCreateInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userMagicLinkCreate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UserMagicLinkError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"userMagicLinkCode"},"name":{"kind":"Name","value":"errorCode"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ServerError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"serverErrorCode"},"name":{"kind":"Name","value":"errorCode"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]}}]}}]} as unknown as DocumentNode<UserMagicLinkCreateMutationPayload, UserMagicLinkCreateMutationVariables>;


export type UserMagicLinkCreateVariables = UserMagicLinkCreateMutationVariables;
export type UserMagicLinkCreatePayload = UserMagicLinkCreateMutationPayload;

// remove fragments duplicates
// @ts-ignore
UserMagicLinkCreateDocument['definitions'] = [ ...new Set(UserMagicLinkCreateDocument.definitions) ];

export default UserMagicLinkCreateDocument;